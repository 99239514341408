import { useQuery } from '@tanstack/react-query'
import { useAuth } from '../../context/AuthContext'
import { analysisManagerKeys } from './queries'
import { Analysis } from './types'

export const useAnalysisManager = () => {
  const { backendRequest } = useAuth()

  return useQuery<Analysis[]>({
    queryKey: analysisManagerKeys.all,
    queryFn: async () => {
      const responseAnalyses = await backendRequest({
        method: 'GET',
        endpoint: '/api/analyses-manager',
        requiresAuth: true,
      })

      const data = responseAnalyses.data

      if (data === undefined) {
        throw Error('Data unavailable')
      }
      return data as Analysis[]
    },
    refetchOnWindowFocus: true,
  })
}

export const useAnalysis = (analysisId: number) => {
  const { backendRequest } = useAuth()

  return useQuery<Analysis>({
    queryKey: analysisManagerKeys.analysisManager(analysisId),
    queryFn: async () => {
      const response = await backendRequest({
        method: 'GET',
        endpoint: `/api/analyses-manager/${analysisId}`,
        requiresAuth: true,
      })
      return response.data as Analysis
    },
    enabled: !!analysisId,
    refetchOnWindowFocus: true,
    staleTime: 300_000,
  })
}
