import React, { useMemo } from 'react'
import { Box, Skeleton } from '@mui/material'
import Plot from 'react-plotly.js'
import { HighlightedSampleData } from '../prsResults/types'

export type ZScorePlotData = {
  groupName: string
  y: number[]
  yText: string[]
}

type Props = {
  data: ZScorePlotData[]
  isLoading: boolean
  controlClass: string
  highlightedSampleData?: HighlightedSampleData
}

const calculateZScore = (data: ZScorePlotData[], controlClass: string, highlightedPatientData: HighlightedSampleData | undefined) => {
  const controlScore = data.find((group) => group.groupName === controlClass)?.y || []
  let totalControlScore = controlScore.reduce((acc, score) => acc + score, 0)
  let controlScoreLength = controlScore.length

  if (highlightedPatientData && highlightedPatientData.isControl) {
    controlScoreLength -= 1
    totalControlScore -= highlightedPatientData.y
  }

  if (controlScoreLength > 0 && highlightedPatientData) {
    const prs_average_healthy = totalControlScore / controlScoreLength
    const sd_z = Math.sqrt(controlScore.reduce((acc, score) => acc + Math.pow(score - prs_average_healthy, 2), 0) / controlScoreLength)
    return (highlightedPatientData.y - prs_average_healthy) / sd_z
  }
  return 0
}

const ZScorePlot: React.FC<Props> = (props) => {
  const zScore = useMemo(
    () => calculateZScore(props.data, props.controlClass, props.highlightedSampleData),
    [props.data, props.controlClass, props.highlightedSampleData]
  )

  return !props.isLoading ? (
    <Plot
      data={[
        {
          x: Array.from({ length: 801 }, (_, i) => -4 + i * 0.01),
          y: Array.from({ length: 801 }, (_, i) => {
            const x = -4 + i * 0.01
            return (1 / Math.sqrt(2 * Math.PI)) * Math.exp(-0.5 * x * x)
          }),
          type: 'scatter',
          mode: 'lines',
          name: 'Normal Distribution',
        },
        {
          x: new Array(100).fill(zScore),
          y: Array.from({ length: 100 }, (_, i) => i * (0.42 / 99)),
          mode: 'markers',
          marker: {
            size: 5,
            color: 'rgba(0,0,0,0)',
          },
          hoverinfo: 'text',
          hovertext: new Array(100).fill(`Z-Score: ${zScore.toFixed(2)}, Patient: ${props.highlightedSampleData?.yText}`),
          type: 'scatter',
        },
      ]}
      layout={{
        paper_bgcolor: 'transparent',
        plot_bgcolor: 'transparent',
        xaxis: { title: 'Z Scores' },
        yaxis: { title: 'Probability', range: [0, 0.42] },
        showlegend: false,
        shapes: [
          {
            type: 'line',
            x0: zScore,
            y0: 0,
            x1: zScore,
            y1: 0.42,
            line: { color: 'red', width: 2, dash: 'dot' },
          },
        ],
      }}
      style={{ width: '100%', height: '100%' }}
    />
  ) : (
    <Box width='100%' height='100%' p={3}>
      <Skeleton variant='rounded' height='90%' />
    </Box>
  )
}

export default ZScorePlot
