import React, { useEffect, useMemo, useState } from 'react'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

import './App.css'
import { AuthProvider } from './context/AuthContext'
import AnalysisManager from './pages/AnalysisManager/AnalysisManager'
import NavBar from './components/NavBar'
import Home from './pages/Home'
import ProtectedRoute from './components/ProtectedRoute'
import { ThemeProvider, createTheme } from '@mui/material'
import TargetDataManager from './pages/TargetDataManager/TargetDataManager'
import BaseDataManager from './pages/BaseDatamanager/BaseDataManager'
import GeneralAnalysis from './pages/GeneralAnalysis'
import PathwayBasedAnalysis from './pages/PathwayBasedAnalysis'
import ProcessBasedAnalysis from './pages/ProcessBasedAnalysis'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

type PaletteProps = {
  mode: string
}

export type ThemeProps = {
  palette: PaletteProps
}

const App: React.FC = () => {
  const [darkMode, setDarkMode] = useState<boolean>(false)

  const changeMode = () => {
    setDarkMode(!darkMode)
    localStorage.setItem('darkMode', (!darkMode).toString())
  }

  useEffect(() => {
    if (localStorage.getItem('darkMode')) {
      if (localStorage.getItem('darkMode') === 'true') {
        setDarkMode(true)
      } else {
        setDarkMode(false)
      }
    }
  }, [])

  const theme = useMemo(() => createTheme({ palette: { mode: darkMode ? 'dark' : 'light' } }), [darkMode])

  return (
    <QueryClientProvider client={queryClient}>
      <div className='App'>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider maxSnack={3} preventDuplicate={true} autoHideDuration={8000} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
            <AuthProvider>
              <Router>
                <NavBar changeMode={changeMode} theme={theme} />
                <Routes>
                  <Route path='/' element={<Home />} />
                  <Route
                    path='/analyses-manager'
                    element={
                      <ProtectedRoute>
                        <AnalysisManager />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/analysis/general/:analysisId'
                    element={
                      <ProtectedRoute>
                        <GeneralAnalysis />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/analysis/pathway_based/:analysisId'
                    element={
                      <ProtectedRoute>
                        <PathwayBasedAnalysis />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/analysis/process_based/:analysisId'
                    element={
                      <ProtectedRoute>
                        <ProcessBasedAnalysis />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/target-data-manager'
                    element={
                      <ProtectedRoute>
                        <TargetDataManager />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/base-data-manager'
                    element={
                      <ProtectedRoute>
                        <BaseDataManager />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
              </Router>
            </AuthProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
