import React, { useState, useMemo } from 'react'

import { Button, ButtonGroup, Chip } from '@mui/material'
import { DataGridProps, GridRenderCellParams } from '@mui/x-data-grid'
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

import { iconsObj } from '../../icons/Icons'
import PageHeading from '../../components/PageHeading'
import CustomDataGrid from '../../components/CustomDataGrid'
import { useAuth } from '../../context/AuthContext'
import TableIconButton from '../../components/buttons/TableIconButton'
import BackendRequestDialog from '../../components/dialogs/BackendRequestDialog'
import TargetAndBaseDataButton from '../../components/buttons/TargetAndBaseDataButton'
import TablePageSkeleton from '../../components/TablePageSkeleton'
import { AnalysisType } from '../../enums/AnalysisManager'
import { useIsMutating, useQueryClient } from '@tanstack/react-query'
import { useAnalysisManager } from './hooks'
import { analysisManagerKeys } from './queries'
import { Analysis, ExtendedAnalyses } from './types'
import ManageAnalysisDialog from '../../components/dialogs/ManageAnalysisDialog'

import { ActionType } from '../../components/dialogs/ManageAnalysisDialog'

const analysisTypeChip: { [key in AnalysisType]: { color: string; label: string } } = {
  [AnalysisType.general]: { color: '#0891b2', label: 'General' },
  [AnalysisType.pathway_based]: { color: '#0d9488', label: 'Pathway Based' },
  [AnalysisType.process_based]: { color: '#059669', label: 'Process Based' },
}

const AnalysisManager: React.FC = () => {
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false)
  const [showAddAnalysisDialog, setShowAddAnalysisDialog] = useState<boolean>(false)
  const [selectedAnalyisId, setSelectedAnalyisId] = useState<number>(0)
  const [action, setAction] = useState<ActionType>(ActionType.ADD)
  const [selectedAnalysisData, setSelectedAnalysisData] = useState<Analysis | null>(null)

  const handleShowAnalysisDialog = (actionType: ActionType) => {
    setShowAddAnalysisDialog((showAddAnalysisDialog) => !showAddAnalysisDialog)
    setAction(actionType)
  }

  const { logout, backendRequest } = useAuth()
  const navigate = useNavigate()

  const queryClient = useQueryClient()
  const isMutating = useIsMutating()

  const analysisManager = useAnalysisManager()
  let analysisManagerData: ExtendedAnalyses[] = []

  if (analysisManager.isSuccess) {
    console.log(analysisManager.data)

    analysisManagerData = analysisManager.data.map((analysis: Analysis) => {
      const targetData = (
        <TargetAndBaseDataButton
          id={analysis.target_data_manager.id}
          name={analysis.target_data_manager.name}
          description={analysis.target_data_manager.description}
          rootEfoDiseaseId={analysis.target_data_manager.root_efo_disease_id}
        />
      )
      const baseData = (
        <TargetAndBaseDataButton
          id={analysis.base_data_manager.id}
          name={analysis.base_data_manager.name}
          description={analysis.base_data_manager.description}
          rootEfoDiseaseId={analysis.base_data_manager.root_efo_disease_id}
        />
      )
      const actions = (
        <ButtonGroup>
          <TableIconButton
            icon={iconsObj.OPEN}
            size='small'
            tooltipText='Open Analysis'
            id='open_analysis_btn'
            onClick={() => {
              navigate(`/analysis/${analysis.analysis_type}/` + analysis.id)
            }}
            onMouseEnter={() => {
              queryClient.prefetchQuery<Analysis>({
                queryKey: analysisManagerKeys.analysisManager(analysis.id),
                queryFn: async () => {
                  const response = await backendRequest({
                    method: 'GET',
                    endpoint: `/api/analyses-manager/${analysis.id}`,
                    requiresAuth: true,
                  })
                  return response.data as Analysis
                },
                staleTime: 60_000,
              })
            }}
          />
          <TableIconButton
            icon={iconsObj.MODIFY}
            size='small'
            tooltipText='Edit Analysis'
            id='modify_analysis_btn'
            onClick={() => {
              setSelectedAnalysisData(analysis)
              setAction(ActionType.EDIT)
              handleShowAnalysisDialog(ActionType.EDIT)
            }}
          />
          <TableIconButton
            icon={iconsObj.DELETE}
            size='small'
            tooltipText='Delete Analysis'
            id='delete_analysis_btn'
            onClick={() => {
              setSelectedAnalyisId(analysis.id)
              setShowRemoveDialog(true)
            }}
          />
        </ButtonGroup>
      )
      return { ...analysis, actions, targetData, baseData }
    })
  }

  if (analysisManager.isError) {
    enqueueSnackbar(`An error occurred. ${analysisManager.error.message}`, { variant: 'error' })
    logout()
  }

  const columnSpec = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        editable: false,
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: 'name',
        headerName: 'Name',
        editable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
      },
      {
        field: 'disease_trait',
        headerName: 'Disease / Trait',
        editable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => params.row.root_efo_disease.disease,
      },
      {
        field: 'analysis_type',
        headerName: 'Type',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        width: 150,
        renderCell: (params: GridRenderCellParams) => (
          <Chip
            size='small'
            label={analysisTypeChip[params.row.analysis_type as AnalysisType].label}
            sx={{ backgroundColor: analysisTypeChip[params.row.analysis_type as AnalysisType].color, color: 'white' }}
          />
        ),
      },
      {
        field: 'target_data',
        headerName: 'Target Data',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        width: 150,
        renderCell: (params: GridRenderCellParams) => params.row.targetData,
      },
      {
        field: 'base_data',
        headerName: 'Base Data',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        width: 150,
        renderCell: (params: GridRenderCellParams) => params.row.baseData,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        width: 150,
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => params.row.actions,
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <TablePageSkeleton
        headerGridLeft={<PageHeading icon={iconsObj.ANALYSES}>Analyses Manager</PageHeading>}
        headerGridRight={
          <Button variant='outlined' startIcon={iconsObj.ADD} id='add-analysis-btn' onClick={() => handleShowAnalysisDialog(ActionType.ADD)}>
            Add Analysis
          </Button>
        }
        table={
          <CustomDataGrid rows={analysisManagerData} columns={columnSpec as DataGridProps['columns']} isLoading={analysisManager.isLoading || isMutating > 0} />
        }
      />
      <BackendRequestDialog
        show={showRemoveDialog}
        setShow={setShowRemoveDialog}
        handleSuccess={() => {
          analysisManager.refetch()
        }}
        endpoint={`/api/analyses-manager/${selectedAnalyisId}`}
        requestMethod='DELETE'
        title={'Are you sure you want to remove Analysis ' + selectedAnalyisId + '?'}
        buttonText='Remove'
        buttonColor='error'
        buttonIcon={iconsObj.DELETE}
        id='remove-analysis-dialog'
      />
      <ManageAnalysisDialog
        open={showAddAnalysisDialog}
        setOpen={() => handleShowAnalysisDialog(action)}
        analysisOptions={{ actionType: action, analysisData: selectedAnalysisData }}
      />
    </React.Fragment>
  )
}

export default AnalysisManager
