import React from 'react'
import { Controller } from 'react-hook-form'
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material'

import FormProps, { Option } from './FormProps'

type FormInputSelectProps = FormProps & {
  options: Option[]
  label: string
  required?: boolean
  isLoading?: boolean
  placeholder?: string
}

const FormInputSelect: React.FC<FormInputSelectProps> = ({ name, control, options, label, required = false, isLoading = false, placeholder, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl variant='outlined' size='small' fullWidth {...props}>
          <InputLabel required={required}>{label}</InputLabel>
          <Select
            label={label}
            onChange={onChange}
            value={value}
            disabled={isLoading}
            sx={{
              mx: 1,
              '.MuiSelect-select': {
                textAlign: 'left',
              },
            }}
          >
            {placeholder ? (
              <MenuItem value={placeholder}>{placeholder}</MenuItem>
            ) : null}
            {options.map((option) => (
              <MenuItem key={option.id} value={option.label}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  )
}

export default FormInputSelect