import { useQuery } from '@tanstack/react-query'
import { useAuth } from '../../context/AuthContext'
import { targetDataManagerKeys } from './queries'
import { TargetDataResponse } from '../../components/dialogs/TargetDataDialog'
import { TargetOrBaseData } from './types'

export const useTargetDataManager = () => {
  const { backendRequest } = useAuth()

  return useQuery<TargetOrBaseData[]>({
    queryKey: targetDataManagerKeys.all,
    queryFn: async () => {
      const responseTargetData = await backendRequest({
        method: 'GET',
        endpoint: '/api/target-data-manager/all',
        requiresAuth: true,
      })

      const data = responseTargetData.data as TargetOrBaseData[] | undefined

      if (data === undefined) {
        throw Error('Target Data Manager -  unavailable data')
      }
      return data
    },
    refetchOnWindowFocus: true,
  })
}

export const usePatientsAndClasses = (targetDataId: number, enabled = true) => {
  const { backendRequest } = useAuth()

  return useQuery({
    queryKey: targetDataManagerKeys.patientAndClasses(targetDataId),
    queryFn: async () => {
      const responseTargetData = await backendRequest({
        method: 'GET',
        endpoint: `/api/target-data-manager/assign-patients-and-classes/${targetDataId}`,
        requiresAuth: true,
      })

      const data = responseTargetData.data as TargetDataResponse | undefined

      if (data === undefined) {
        throw Error('Target Data Manager -  unavailable data')
      }
      return data
    },
    enabled: !!enabled,
    refetchOnWindowFocus: true,
    staleTime: 300_000,
  })
}

export const useTargetData = (dataManagerId: number) => {
  const { backendRequest } = useAuth()

  return useQuery<TargetOrBaseData>({
    queryKey: targetDataManagerKeys.targetDataManager(dataManagerId),
    queryFn: async () => {
      const response = await backendRequest({
        method: 'GET',
        endpoint: `/api/target-data-manager/${dataManagerId}`,
        requiresAuth: true,
      })
      return response.data as TargetOrBaseData
    },
    enabled: !!dataManagerId,
    refetchOnWindowFocus: true,
  })
}
