import React, { useEffect, useMemo, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton, Paper, Stack, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'

import { useAuth } from '../../context/AuthContext'
import { ExtendedPopulation } from '../new-base-data-stepper/BaseDataPopulationStep'
import { FolderNode, dbIdsToIds } from '../MultiSelectCheckboxTreeView'
import { iconsObj } from '../../icons/Icons'
import HorizontalLinearStepper from '../HorizontalLinearStepper'
import BaseDataGeneralInfoStep from '../new-base-data-stepper/BaseDataGeneralInfoStep'
import BaseDataSubDiseasesStep from '../new-base-data-stepper/BaseDataSubDiseasesStep'
import BaseDataPopulationStep from '../new-base-data-stepper/BaseDataPopulationStep'
import { SubmitHandler, useForm } from 'react-hook-form'
import BaseDataStudiesStep, { Study } from '../new-base-data-stepper/BaseDataStudiesStep'
import { GeneralInfoFormInput, PopulationFormInput, getAllSubDiseasesWithSelection } from './NewBaseDataDialog'
import SimpleBackdrop from '../SimpleBackDrop'
import { AxiosError } from 'axios'
import { QueryObserverResult, RefetchOptions, useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query'
import { baseDataManagerKeys } from '../../pages/BaseDatamanager/queries'
import { useBaseData } from '../../pages/BaseDatamanager/hooks'
import { BaseDataResponse } from '../../pages/BaseDatamanager/types'
import { TargetOrBaseData } from '../../pages/TargetDataManager/types'

type Props = {
  baseDataId: number
  open: boolean
  setOpen: (open: boolean) => void
  setReloadTable: (options?: RefetchOptions) => Promise<QueryObserverResult<TargetOrBaseData[], Error>>
}

const BaseDataDialog: React.FC<Props> = (props) => {
  const queryClient = useQueryClient()
  const isMutating = useIsMutating()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [selectedRootEfoDiseaseId, setSelectedRootEfoDiseaseId] = useState<number>(0)

  const [subDiseases, setSubDiseases] = useState<FolderNode>({} as FolderNode)
  const [selectedSubDiseaseDbIds, setSelectedSubDiseaseDbIds] = useState<number[]>([])
  const [selectedSubDiseaseIds, setSelectedSubDiseaseIds] = useState<number[]>([])
  const [hasSubDiseasesChanged, setHasSubDiseasesChanged] = useState<boolean>(false)
  const [forceTreeViewRerender, setForceTreeViewRerender] = useState<number>(0)

  const [population, setPopulation] = useState<ExtendedPopulation[]>([])
  const [hasPopulationChanged, setHasPopulationChanged] = useState<boolean>(false)

  const [studies, setStudies] = useState<Study[]>([])
  const [selectedStudiesIds, setSelectedStudiesIds] = useState<number[]>([])
  const [hasStudiesChanged, setHasStudiesChanged] = useState<boolean>(false)

  const [activeStep, setActiveStep] = useState<number>(0)

  const { backendRequest } = useAuth()

  const mutationName = useMutation({
    mutationFn: async ({ selectedName, baseDataId, endpointType }: { selectedName: string; baseDataId: number; endpointType: string }) => {
      let params = {}
      if (endpointType === 'name') {
        params = {
          name: selectedName,
        }
      } else if (endpointType === 'description') {
        params = {
          description: selectedName,
        }
      } else {
        return
      }
      const updatedData = await backendRequest({
        method: 'PUT',
        endpoint: `/api/base-data-manager/${baseDataId}/${endpointType}`,
        requiresAuth: true,
        params: { ...params },
      })
      return updatedData.data
    },
    onSuccess: (updatedItem: TargetOrBaseData | undefined) => {
      if (updatedItem === undefined) {
        return
      }

      queryClient.setQueryData(baseDataManagerKeys.all, (oldData: TargetOrBaseData[]) => {
        if (!oldData) {
          return oldData
        }
        return oldData.map((item) => (item.id === updatedItem.id ? updatedItem : item))
      })

      queryClient.setQueryData(baseDataManagerKeys.baseDataManager(props.baseDataId), (oldData: BaseDataResponse) => {
        return {
          ...oldData,
          name: updatedItem.name,
          description: updatedItem.description,
        }
      })
      enqueueSnackbar(`Base data with ID ${props.baseDataId} was successfully saved.`, { variant: 'success' })
      handleClose()
    },
    onError: (error: AxiosError) => {
      console.log(error)
      enqueueSnackbar(`An error occurred while saving the target data. ${error.response?.statusText}`, { variant: 'error' })
      handleClose()
    },
  })

  const {
    control: controlGeneralInfo,
    reset: resetGeneralInfo,
    handleSubmit: handleSubmitGeneralInfo,
    setValue: setValueGeneralInfo,
    watch: watchGeneralInfo,
  } = useForm<GeneralInfoFormInput>()

  const selectedRootEfoDisease = watchGeneralInfo('rootEfoDisease')
  const selectedName = watchGeneralInfo('name')
  const selectedDescription = watchGeneralInfo('description')

  const {
    unregister: unregisterPopulation,
    control: controlPopulation,
    reset: resetPopulation,
    handleSubmit: handleSubmitPopulation,
  } = useForm<PopulationFormInput>()

  const baseDataResponse = useBaseData(props.baseDataId)
  if (baseDataResponse.isError) {
    enqueueSnackbar(`An error occurred. Please try again later.`, { variant: 'error' })
  }

  useEffect(() => {
    if (baseDataResponse.data == undefined) {
      return
    }
    const baseData = baseDataResponse.data as BaseDataResponse

    setValueGeneralInfo('name', baseData.name)
    setValueGeneralInfo('description', baseData.description)
    setValueGeneralInfo('rootEfoDisease', { label: baseData.root_efo_disease.disease, id: baseData.root_efo_disease.id })

    setSelectedRootEfoDiseaseId(baseData.root_efo_disease.id)
    setSelectedSubDiseaseDbIds(baseData.efo_diseases.filter((disease) => disease.is_selected == true).map((disease) => disease.efo_disease.id))

    setPopulation(baseData.population.map((pop) => ({ ...pop.gwas_population, isSelected: pop.is_selected })))
    const defaultValues = baseData.population?.reduce(
      (acc, { gwas_population, is_selected }) => ({
        ...acc,
        [gwas_population.population]: is_selected,
      }),
      {}
    )
    resetPopulation(defaultValues)

    setStudies(baseData.studies.map((study) => study.gwas_study))
    setSelectedStudiesIds(baseData.studies.filter((study) => study.is_selected == true).map((study) => study.gwas_study.id))
  }, [baseDataResponse.data])

  useEffect(() => {
    if (activeStep === 1 && selectedSubDiseaseDbIds.length > 0 && selectedSubDiseaseIds.length === 0) {
      const initialIds = dbIdsToIds(subDiseases, selectedSubDiseaseDbIds)
      setSelectedSubDiseaseIds(initialIds)
      setForceTreeViewRerender((forceTreeViewRerender) => forceTreeViewRerender + 1)
    }
  }, [subDiseases])

  useEffect(() => {
    if (!baseDataResponse.data) {
      return
    }
    if (props.open && activeStep === 3) {
      const hasStudiesChanged =
        JSON.stringify(selectedStudiesIds.sort()) !==
        (baseDataResponse.data.studies &&
          JSON.stringify(
            baseDataResponse.data.studies
              .filter((study) => study.is_selected === true)
              .map((study) => study.gwas_study.id)
              .sort()
          ))
      setHasStudiesChanged(hasStudiesChanged)
    }
  }, [selectedStudiesIds])

  const hasDataChanged = useMemo(() => {
    const hasChanges =
      !!baseDataResponse.data &&
      (selectedName !== baseDataResponse.data.name ||
        selectedDescription !== baseDataResponse.data.description ||
        hasSubDiseasesChanged ||
        hasPopulationChanged ||
        hasStudiesChanged)
    return hasChanges
  }, [selectedName, selectedDescription, hasSubDiseasesChanged, selectedStudiesIds, hasStudiesChanged])

  const handleClose = () => {
    props.setOpen(false)

    resetGeneralInfo()
    resetPopulation()

    setSelectedRootEfoDiseaseId(0)

    setSelectedSubDiseaseDbIds([])
    setSelectedSubDiseaseIds([])
    setSubDiseases({} as FolderNode)

    setPopulation([])

    setStudies([])
    setSelectedStudiesIds([])
    setActiveStep(0)
  }

  const updateBaseData = async () => {
    setIsLoading(true)
    if (!baseDataResponse.data) {
      return
    }

    let responseMessage = ''

    const queries = queryClient.getQueryCache().findAll()
    let nameOrDescriptionHasChanged = false
    if (selectedName !== baseDataResponse.data.name) {
      mutationName.mutate({ selectedName: selectedName, baseDataId: props.baseDataId, endpointType: 'name' })
      nameOrDescriptionHasChanged = true
    }

    if (selectedDescription !== baseDataResponse.data.description) {
      mutationName.mutate({ selectedName: selectedDescription, baseDataId: props.baseDataId, endpointType: 'description' })
      nameOrDescriptionHasChanged = true
    }

    // remove analysis managers from cache
    // TODO: it will be more sufficient to rather mutate these managers
    if (nameOrDescriptionHasChanged) {
      const matchingKeys = queries.filter(({ queryKey }) => queryKey[0] === 'analysis-manager')

      matchingKeys.forEach(({ queryKey: key }) => {
        queryClient.removeQueries({ queryKey: key })
      })
    }

    if (hasPopulationChanged || hasSubDiseasesChanged || hasStudiesChanged) {
      const efoSubDiseases = getAllSubDiseasesWithSelection(subDiseases, selectedSubDiseaseDbIds)

      try {
        await backendRequest({
          method: 'PUT',
          endpoint: `/api/base-data-manager/${props.baseDataId}/assignments`,
          requiresAuth: true,
          body: {
            population: population.map((pop) => ({ id: pop.id, is_selected: pop.isSelected })),
            studies: studies.map((study) => ({ id: study.id, is_selected: selectedStudiesIds.includes(study.id) })),
            efo_diseases: efoSubDiseases.map((subDisease) => ({ id: subDisease.db_id, is_selected: subDisease.is_selected })),
          },
        })
        baseDataResponse.refetch()

        // filter only these query kyes, which includes in a queryKey ['analysis'] and it's baseDataId in object
        const matchingKeys = queries
          .filter(({ queryKey }) => queryKey[0] === 'analysis' && (queryKey[1] as { baseDataId?: number })?.baseDataId === props.baseDataId)
          .map(({ queryKey }) => queryKey)

        matchingKeys.forEach((key) => {
          queryClient.removeQueries({ queryKey: key })
        })
        queryClient.removeQueries({ queryKey: baseDataManagerKeys.snps(props.baseDataId) })
        enqueueSnackbar(`Base data with ID ${props.baseDataId} was successfully saved.`, { variant: 'success' })
        handleClose()
      } catch (error) {
        const err = error as AxiosError
        responseMessage += err.response?.statusText
        enqueueSnackbar(`An error occurred while saving the target data. ${responseMessage}`, { variant: 'error' })
      }
    }
    setIsLoading(false)
  }

  const onGeneralInfoSubmit: SubmitHandler<GeneralInfoFormInput> = async (data) => {
    if (!baseDataResponse.data) {
      return
    }

    setSelectedRootEfoDiseaseId(data.rootEfoDisease.id)
    if (baseDataResponse.data.root_efo_disease.id !== data.rootEfoDisease.id) {
      setSelectedSubDiseaseIds([])
      setSelectedSubDiseaseDbIds([])
    }
    setActiveStep(1)
  }

  const onSubDiseasesSubmit = () => {
    if (selectedSubDiseaseDbIds.length === 0) {
      enqueueSnackbar('Please select at least one sub-disease', { variant: 'error' })
      return
    }
    setActiveStep(2)
  }

  const onPopulationSubmit: SubmitHandler<PopulationFormInput> = async (data) => {
    const selectedPopulation: ExtendedPopulation[] = []

    // Find if at least one population is selected
    if (Object.values(data).every((isSelected) => !isSelected)) {
      enqueueSnackbar('Please select at least one population', { variant: 'error' })
      return
    }

    Object.entries(data).forEach(([populationName, isSelected]) => {
      const pop = population.find((pop) => pop.population === populationName)
      if (pop) {
        selectedPopulation.push({ id: pop.id, population: pop.population, isSelected: isSelected })
      }
    })

    // Find out if there are any changes in the selected population
    const hasPopulationChanged = selectedPopulation.some((pop) => pop.isSelected !== population.find((p) => p.id === pop.id)?.isSelected)
    setHasPopulationChanged(hasPopulationChanged)
    if (hasPopulationChanged) {
      setSelectedStudiesIds([])
    }

    setPopulation(selectedPopulation)
    setActiveStep(3)
  }

  const onStudiesSubmit = () => {
    if (selectedStudiesIds.length === 0) {
      enqueueSnackbar('Please select at least one study', { variant: 'error' })
      return
    }
    updateBaseData()
  }

  const customHandleNext = () => {
    if (activeStep === 0) {
      handleSubmitGeneralInfo(onGeneralInfoSubmit)()
    } else if (activeStep === 1) {
      onSubDiseasesSubmit()
    } else if (activeStep === 2) {
      handleSubmitPopulation(onPopulationSubmit)()
    } else if (activeStep === 3) {
      onStudiesSubmit()
    }
  }

  const steps = [
    {
      title: 'Select General Information',
      id: 'select-general-information',
      component: (
        <BaseDataGeneralInfoStep
          initialRootEfoDisease={selectedRootEfoDisease}
          efoDiseases={[]}
          setEfoDiseases={() => {}}
          control={controlGeneralInfo}
          setValue={setValueGeneralInfo}
          disabledRootEfoDisease={true}
        />
      ),
    },
    {
      title: 'Select Sub-diseases',
      id: 'select-sub-diseases',
      component: (
        <BaseDataSubDiseasesStep
          subDiseases={subDiseases}
          setSubDiseases={setSubDiseases}
          selectedRootEfoDiseaseId={selectedRootEfoDiseaseId}
          selectedSubDiseaseIds={selectedSubDiseaseIds}
          setSelectedSubDiseaseIds={setSelectedSubDiseaseIds}
          selectedSubDiseaseDbIds={selectedSubDiseaseDbIds}
          setSelectedSubDiseaseDbIds={setSelectedSubDiseaseDbIds}
          setHasSubDiseasesChanged={setHasSubDiseasesChanged}
          forceTreeViewRerender={forceTreeViewRerender}
        />
      ),
    },
    {
      title: 'Select Population',
      id: 'select-population',
      component: (
        <BaseDataPopulationStep
          population={population}
          setPopulation={setPopulation}
          subDiseaseIds={selectedSubDiseaseIds}
          control={controlPopulation}
          reset={resetPopulation}
          hasSubDiseasesChanged={hasSubDiseasesChanged}
          setHasSubDiseasesChanged={setHasSubDiseasesChanged}
          setHasPopulationChanged={setHasPopulationChanged}
          unregister={unregisterPopulation}
        />
      ),
    },
    {
      title: 'Select Studies',
      id: 'select-studies',
      component: (
        <BaseDataStudiesStep
          studies={studies}
          setStudies={setStudies}
          selectedSubDiseaseIds={selectedSubDiseaseDbIds}
          selectedPopulationIds={population.filter((pop) => pop.isSelected).map((pop) => pop.id)}
          setSelectedStudiesIds={setSelectedStudiesIds}
          selectedStudiesIds={selectedStudiesIds}
          hasPopulationChanged={hasPopulationChanged}
          setHasPopulationChanged={setHasPopulationChanged}
        />
      ),
    },
  ]

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={false}
        scroll='paper'
        open={props.open}
        onClose={handleClose}
        aria-labelledby='new-base-data-dialog'
        aria-describedby='new-base-data-dialog'
      >
        <DialogTitle>
          <Stack direction='row' spacing={1}>
            <Paper elevation={0} sx={{ backgroundColor: 'inherit', pt: 0.5 }}>
              {iconsObj.BASE_DATA}
            </Paper>
            <Typography variant='h5'> {baseDataResponse?.data?.name}</Typography>
          </Stack>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {iconsObj.CLOSE}
        </IconButton>
        <DialogContent sx={{ height: '90vh', overflow: 'auto' }}>
          <HorizontalLinearStepper
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            customHandleNext={customHandleNext}
            lastStepButtonName='Save'
            disabledLastStepButton={!hasDataChanged}
          />
          {steps[activeStep].component}
        </DialogContent>
      </Dialog>
      <SimpleBackdrop isLoading={baseDataResponse.isLoading || isLoading || isMutating > 0} />
    </React.Fragment>
  )
}

export default BaseDataDialog
