import { useQuery } from '@tanstack/react-query'
import { useAuth } from '../../context/AuthContext'
import { baseDataManagerKeys } from './queries'
import { BaseDataResponse } from './types'
import { TargetOrBaseData } from '../TargetDataManager/types'

export const useBaseDataManager = () => {
  const { backendRequest } = useAuth()

  return useQuery<TargetOrBaseData[]>({
    queryKey: baseDataManagerKeys.all,
    queryFn: async () => {
      const response = await backendRequest({
        method: 'GET',
        endpoint: '/api/base-data-manager/all',
        requiresAuth: true,
      })
      if (response.data === undefined) {
        throw Error('No data is currently available.')
      }
      return response.data
    },
    refetchOnWindowFocus: true,
  })
}

export const useBaseData = (id: number) => {
  const { backendRequest } = useAuth()

  return useQuery<BaseDataResponse>({
    queryKey: baseDataManagerKeys.baseDataManager(id),
    queryFn: async () => {
      const responseBaseData = await backendRequest({
        method: 'GET',
        endpoint: `/api/base-data-manager/${id}`,
        requiresAuth: true,
      })
      return responseBaseData.data
    },
    refetchOnWindowFocus: true,
    enabled: !!id,
  })
}
