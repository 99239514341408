import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, Typography } from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'

import { iconsObj } from '../../icons/Icons'
import { useAuth } from '../../context/AuthContext'
import SubmitButton from '../buttons/SubmitButton'
import FormInputComboBox from '../forms/FormInputComboBox'
import { Option } from '../forms/FormProps'
import FormInputText from '../forms/FormInputText'
import { enqueueSnackbar } from 'notistack'
import { AnalysisType } from '../../enums/AnalysisManager'
import FormInputRadioGroup from '../forms/FormInputRadioGroup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { analysisManagerKeys } from '../../pages/AnalysisManager/queries'
import { Analysis, EfoDisease } from '../../pages/AnalysisManager/types'
import { TargetOrBaseData } from '../../pages/TargetDataManager/types'
import { prsCalculationKeys } from '../prsResults/queries'
import { baseDataManagerKeys } from '../../pages/BaseDatamanager/queries'
import { targetDataManagerKeys } from '../../pages/TargetDataManager/queries'

export enum ActionType {
  ADD = 'add',
  EDIT = 'edit',
}

type AnalysisOptions = {
  actionType: ActionType
  analysisData?: Analysis | null
}

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  analysisOptions: AnalysisOptions
}

type FormInput = {
  name: string
  baseData: { id: string; label: string }
  targetData: { id: string; label: string }
  rootEfoDisease: { id: string; label: string }
  analysisType: { value: string; label: string }
}

const ManageAnalysisDialog: React.FC<Props> = (props) => {
  const [efoDiseases, setEfoDiseases] = useState<Option[]>([])
  const [targetData, setTargetData] = useState<Option[]>([])
  const [baseData, setBaseData] = useState<Option[]>([])

  const [rootEfoDisease, setRootEfoDisease] = useState<string>('')

  const { handleSubmit, reset, control, watch } = useForm<FormInput>()

  const { backendRequest, logout } = useAuth()

  const selectedRootEfoDisease = watch('rootEfoDisease')
  const defaultAnalysisType = (props.analysisOptions.analysisData?.analysis_type as AnalysisType) || AnalysisType.pathway_based

  useEffect(() => {
    if (props.open && props.analysisOptions.actionType === ActionType.EDIT && props.analysisOptions.analysisData) {
      reset({
        name: props.analysisOptions.analysisData.name,
        baseData: { id: props.analysisOptions.analysisData.base_data_manager.id.toString(), label: props.analysisOptions.analysisData.base_data_manager.name },
        targetData: {
          id: props.analysisOptions.analysisData.target_data_manager.id.toString(),
          label: props.analysisOptions.analysisData.target_data_manager.name,
        },
        rootEfoDisease: {
          id: props.analysisOptions.analysisData.root_efo_disease.id.toString(),
          label: props.analysisOptions.analysisData.root_efo_disease.disease,
        },
      })
    } else {
      reset({ name: '', baseData: {}, targetData: {} })
    }
  }, [props.open, props.analysisOptions.actionType, props.analysisOptions.analysisData])

  const queryClient = useQueryClient()

  const mutationAddAnalysis = useMutation({
    mutationFn: async ({ data }: { data: FormInput }) => {
      let method: 'GET' | 'POST' | 'DELETE' | 'PUT' = 'POST'
      let endpoint = '/api/analyses-manager'

      if (props.analysisOptions.actionType === ActionType.EDIT) {
        method = 'PUT'
        endpoint = `/api/analyses-manager/${props.analysisOptions.analysisData?.id}`
      }
      const responseAnalysesManager = await backendRequest({
        method: method,
        endpoint: endpoint,
        body: {
          name: data.name,
          analysis_type: data.analysisType,
          base_data_manager_id: data.baseData.id,
          target_data_manager_id: data.targetData.id,
          root_efo_disease_id: data.rootEfoDisease.id,
          highlighted_patient_id: null,
          p_value_threshold_id: null,
        },
        requiresAuth: true,
      })

      if (props.analysisOptions.actionType === ActionType.EDIT) {
        if (!responseAnalysesManager.data) {
          throw Error('Data unavailable')
        }

        const analysisData = props.analysisOptions.analysisData
        if (analysisData?.base_data_manager && analysisData.target_data_manager) {
          queryClient.removeQueries({ queryKey: analysisManagerKeys.analysisManager(analysisData.id) })
          queryClient.removeQueries({ queryKey: baseDataManagerKeys.snps(analysisData.base_data_manager.id) })
          queryClient.removeQueries({ queryKey: targetDataManagerKeys.patientAndClasses(analysisData.target_data_manager.id) })

          // remove all keys that starts with 'analysis' and follows with its target and base data ids
          queryClient.removeQueries({ queryKey: prsCalculationKeys.all(analysisData.base_data_manager.id, analysisData.target_data_manager.id) })
        }
      }
      return responseAnalysesManager.data as Analysis | undefined
    },
    onSuccess: (newAnalysys: Analysis | undefined) => {
      if (newAnalysys === undefined) {
        return
      }
      let analysisTypeMsg = 'created'
      if (props.analysisOptions.actionType === ActionType.EDIT) {
        analysisTypeMsg = 'edited'
        queryClient.setQueryData(analysisManagerKeys.all, (oldData: Analysis[]) =>
          oldData.map((analysis) => (analysis.id === props.analysisOptions.analysisData?.id ? newAnalysys : analysis))
        )
      } else {
        queryClient.setQueryData(analysisManagerKeys.all, (oldData: Analysis[]) => (oldData === undefined ? oldData : [...oldData, newAnalysys]))
      }

      enqueueSnackbar(`Analysis with ID ${props.analysisOptions.analysisData?.id} was successfully ${analysisTypeMsg}.`, { variant: 'success' })
    },
  })

  if (mutationAddAnalysis.isError) {
    enqueueSnackbar(`Error in creating analysis: ${mutationAddAnalysis.error.message}`, { variant: 'error' })
  }

  useEffect(() => {
    if (props.open) {
      const getData = async () => {
        const responseEfoDiseases = await backendRequest({
          method: 'GET',
          endpoint: `/api/disease/full-text-search?disease=${rootEfoDisease}`,

          requiresAuth: true,
        })

        const efoDiseasesData = responseEfoDiseases.data as EfoDisease[] | undefined

        return { status: responseEfoDiseases.status, data: efoDiseasesData }
      }
      getData()
        .then((response) => {
          if (response.status === 200 && response.data) {
            setEfoDiseases(response.data.map((efoDisease) => ({ id: efoDisease.id, label: efoDisease.disease })))
          } else if (response.status === 401) {
            logout()
          }
        })
        .catch((error) => {
          enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, { variant: 'error' })
        })
    }
  }, [rootEfoDisease, props.open])

  useEffect(() => {
    if (selectedRootEfoDisease) {
      const getData = async () => {
        const responseTargetData = await backendRequest({
          method: 'GET',
          endpoint: `/api/target-data-manager/disease/${selectedRootEfoDisease.id}`,
          requiresAuth: true,
        })

        const responseBaseData = await backendRequest({
          method: 'GET',
          endpoint: `/api/base-data-manager/disease/${selectedRootEfoDisease.id}`,
          requiresAuth: true,
        })

        return { responseTargetData: responseTargetData, responseBaseData: responseBaseData }
      }
      getData()
        .then(({ responseTargetData, responseBaseData }) => {
          const targetData = responseTargetData.data as TargetOrBaseData[] | undefined
          const baseData = responseBaseData.data as TargetOrBaseData[] | undefined

          if (responseTargetData.status === 200 && targetData) {
            setTargetData(targetData.map((item) => ({ id: item.id, label: item.name })))

            const currentTargetData = watch('targetData')
            // Check if there is currently selected target data and if its
            // ID is not found in the new target data options.
            if (currentTargetData?.id && !targetData.some((item) => item.id.toString() === currentTargetData.id)) {
              // If the currently selected target data is invalid, reset the target data field to an empty value.
              reset({ ...watch(), targetData: {} })
            }
          } else if (responseTargetData.status === 401) {
            logout()
          }

          if (responseBaseData.status === 200 && baseData) {
            setBaseData(baseData.map((item) => ({ id: item.id, label: item.name })))

            const currentBaseData = watch('baseData')
            // Check if there is currently selected base data and if its
            // ID is not found in the new base data options.
            if (currentBaseData?.id && !baseData.some((item) => item.id.toString() === currentBaseData.id)) {
              // If the currently selected base data is invalid, reset the base data field to an empty value.
              reset({ ...watch(), baseData: {} })
            }
          } else if (responseBaseData.status === 401) {
            logout()
          }
        })
        .catch((error) => {
          enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, { variant: 'error' })
        })
    }
  }, [selectedRootEfoDisease])

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    mutationAddAnalysis.mutate({ data: data })
    handleClose()
  }
  const handleClose = () => {
    props.setOpen(false)
    setEfoDiseases([])
    setRootEfoDisease('')
    reset()
  }

  return (
    <Dialog fullWidth maxWidth='sm' open={props.open} onClose={handleClose} aria-labelledby='new-analysis-dialog' aria-describedby='new-analysis-dialog'>
      <DialogTitle>
        <Stack direction='row' spacing={1}>
          <Paper elevation={0} sx={{ backgroundColor: 'inherit', pt: 0.5 }}>
            {iconsObj.ANALYSES}
          </Paper>
          <Typography variant='h5'> {props.analysisOptions.actionType === ActionType.ADD ? 'Add Analysis' : 'Edit Analysis'}</Typography>
        </Stack>
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        {iconsObj.CLOSE}
      </IconButton>
      <DialogContent>
        <Stack spacing={4} px={1} sx={{ width: '100%' }}>
          <FormInputText required id='new-analysis-name' label={'Name'} name='name' control={control} />
          <FormInputComboBox
            required
            id='new-analysis-root-efo-disease'
            label='Root EFO Disease'
            name='rootEfoDisease'
            options={efoDiseases}
            control={control}
            onInputChange={(event, value, reason) => {
              if (reason === 'input') {
                setRootEfoDisease(value)
              }
            }}
          />
          <FormInputComboBox
            required
            id='new-analysis-target-data'
            label='Target Data'
            name='targetData'
            options={targetData}
            control={control}
            disabled={!selectedRootEfoDisease}
          />
          <FormInputComboBox
            required
            id='new-analysis-base-data'
            label='Base Data'
            name='baseData'
            options={baseData}
            control={control}
            disabled={!selectedRootEfoDisease}
          />
          <FormInputRadioGroup
            required
            id='new-analysis-type'
            label='Analysis Type'
            name='analysisType'
            control={control}
            defaultValue={defaultAnalysisType}
            options={[
              { value: AnalysisType.general, id: 'general', label: 'General' },
              { value: AnalysisType.pathway_based, id: 'pathway-based', label: 'Pathway based' },
              { value: AnalysisType.process_based, id: 'process-based', label: 'Process based' },
            ]}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ mx: 3, mb: 2, mt: 3, justifyContent: 'center' }}>
        <SubmitButton id='create-new-analysis-btn' text='Submit' fullWidth onClick={handleSubmit(onSubmit)} startIcon={iconsObj.SUBMIT} />
      </DialogActions>
    </Dialog>
  )
}
export default ManageAnalysisDialog
