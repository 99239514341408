import React from 'react'

import { IconButton } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'

import { iconsObj } from '../../icons/Icons'
import PrsResults from '../prsResults/PrsResults'
import { PrsResultsProps } from '../prsResults/types'

type Props = PrsResultsProps & {
  show: boolean
  setShow: (show: boolean) => void
  baseDataId: number
}

const PrsResultsDialog: React.FC<Props> = (props) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={false}
      open={props.show}
      onClose={() => props.setShow(false)}
      aria-labelledby={'prs-dialog'}
      aria-describedby={'prs-dialog'}
    >
      <IconButton
        aria-label='close'
        onClick={() => props.setShow(false)}
        id='prs-dialog-close-button'
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        {iconsObj.CLOSE}
      </IconButton>
      <DialogContent sx={{ pt: 7 }}>
        <PrsResults
          pageHeading={props.pageHeading}
          baseDataName={props.baseDataName}
          targetDataName={props.targetDataName}
          snpIds={props.snpIds}
          targetData={props.targetData}
          isLoading={props.isLoading}
          analysisId={props.analysisId}
          baseDataId={props.baseDataId}
        />
      </DialogContent>
    </Dialog>
  )
}

export default PrsResultsDialog
