export const targetDataManagerKeys = {
  all: ['target-data-manager'] as const,
  targetDataManager: (id: number) => [...targetDataManagerKeys.all, id] as const,
  disease: (diseaseId: number) => 
    [...targetDataManagerKeys.all, 
      'disease', 
      diseaseId
    ] as const,
  patientAndClasses: (id: number) => ['assign-patients-and-classes', id] as const
}
