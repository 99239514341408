import React, { useEffect, useState } from 'react'

import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { SubmitHandler, useForm } from 'react-hook-form'

import SubmitButton from '../buttons/SubmitButton'
import { iconsObj } from '../../icons/Icons'
import AddSamplesGroupDialog from './AddSamplesGroupDialog'
import { useAuth } from '../../context/AuthContext'
import TargetDataBase, { SampleGroup, TargetDataFormInput, sortSamplesByName } from '../TargetDataBase'
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'
import { TargetOrBaseData } from '../../pages/TargetDataManager/types'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  refetchTable: (options?: RefetchOptions) => Promise<QueryObserverResult<TargetOrBaseData[], Error>>
}

type Patient = {
  id: number
  sample_name: string
}

const NewTargetDataDialog: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [openAddSamplesGroupDialog, setOpenAddSamplesGroupDialog] = useState<boolean>(false)

  const [sampleGroupName, setSampleGroupName] = useState<string>('')
  const [sampleGroups, setSampleGroups] = useState<SampleGroup[]>([{ name: 'Unclassified', samples: [] }])

  const [rootEfoDisease, setRootEfoDisease] = useState<string>('')

  const { register, handleSubmit, reset, control } = useForm<TargetDataFormInput>()

  const { backendRequest, logout } = useAuth()

  useEffect(() => {
    if (props.open === true) {
      const getData = async () => {
        const responsePatients = await backendRequest({
          method: 'GET',
          endpoint: '/api/patients',
          requiresAuth: true,
        })

        const patientsData = responsePatients.data as Patient[] | undefined

        return { status: responsePatients.status, data: patientsData }
      }
      getData()
        .then((response) => {
          if (response.status === 200 && response.data) {
            // Set unclassified setSampleGroup
            const unclassifiedGroup = sampleGroups[0]
            unclassifiedGroup.samples = response.data.map((patient) => ({ id: patient.id, name: patient.sample_name }))

            // Sort unclassified samples by name
            unclassifiedGroup.samples = sortSamplesByName(unclassifiedGroup.samples)

            setSampleGroups([unclassifiedGroup])
          } else if (response.status === 401) {
            logout()
          }
        })
        .catch((error) => {
          enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, { variant: 'error' })
        })
    }
  }, [props.open])

  useEffect(() => {
    if (sampleGroupName) {
      const newSampleGroups = [...sampleGroups]
      newSampleGroups.push({ name: sampleGroupName, samples: [] })
      setSampleGroups(newSampleGroups)
      setSampleGroupName('')
    }
  }, [sampleGroupName])

  const handleClose = () => {
    props.setOpen(false)
    setSampleGroups([{ name: 'Unclassified', samples: [] }])
    setRootEfoDisease('')
    reset()
  }

  const onSubmit: SubmitHandler<TargetDataFormInput> = async (data) => {
    setIsLoading(true)
    // Check if at least one group insted of unclassified exists
    if (sampleGroups.length <= 1) {
      enqueueSnackbar('At least one group must be created.', { variant: 'error' })
      setIsLoading(false)
      return
    }

    const getResponse = async () => {
      const response = await backendRequest({
        method: 'POST',
        endpoint: '/api/target-data-manager',
        requiresAuth: true,
        body: {
          name: data.name,
          description: data.description,
          root_efo_disease_id: data.rootEfoDisease.id,
          patient_classes: sampleGroups.map((group) => {
            return { patient_class_name: group.name, patient_ids: group.samples.map((sample) => sample.id) }
          }),
        },
      })

      return { status: response.status, statusText: response.statusText, data: response.data }
    }
    getResponse()
      .then((response) => {
        if (response && (response.status === 200 || response.status === 204)) {
          props.refetchTable()
          enqueueSnackbar(`Target data with ID ${response.data.id} created successfully.`, { variant: 'success' })
          handleClose()
        } else if (response.status === 401) {
          logout()
        }
      })
      .catch((error) => {
        enqueueSnackbar(`An error occurred while creating the target data. ${error.response?.data.detail}`, { variant: 'error' })
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={false}
        open={props.open}
        onClose={handleClose}
        aria-labelledby='new-target-data-dialog'
        aria-describedby='new-target-data-dialog'
      >
        <DialogTitle>
          <Stack direction='row' spacing={1}>
            <Paper elevation={0} sx={{ backgroundColor: 'inherit', pt: 0.5 }}>
              {iconsObj.TARGET_DATA}
            </Paper>
            <Typography variant='h5'> New Target Data</Typography>
          </Stack>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {iconsObj.CLOSE}
        </IconButton>
        <DialogContent sx={{ height: '80vh', overflow: 'hidden' }}>
          <TargetDataBase
            sampleGroups={sampleGroups}
            setSampleGroups={setSampleGroups}
            rootEfoDisease={rootEfoDisease}
            setRootEfoDisease={setRootEfoDisease}
            control={control}
            register={register}
          />
        </DialogContent>
        <DialogActions sx={{ mx: 2.5, mb: 2, mt: 3, justifyContent: 'center' }}>
          <SubmitButton
            id='create-new-target-data-btn'
            text='Submit'
            fullWidth
            startIcon={iconsObj.SUBMIT}
            onClick={handleSubmit(onSubmit)}
            loading={isLoading}
          />
        </DialogActions>
      </Dialog>
      <AddSamplesGroupDialog
        open={openAddSamplesGroupDialog}
        setOpen={setOpenAddSamplesGroupDialog}
        setSampleGroupName={setSampleGroupName}
        sampleGroups={sampleGroups}
      />
    </React.Fragment>
  )
}

export default NewTargetDataDialog
